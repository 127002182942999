import React from "react";
import { Helmet } from "react-helmet";

// Haal dezelfde festival-gegevens op als in de Header
import { getFestivalInformation } from "../../utils/extractConfigData";

import styles from "./Maintenance.module.css";

const Maintenance = () => {
  const festivalInformation = getFestivalInformation();

  // Bepaal of vandaag 1 maart is (maanden zijn 0-indexed: maart is 2)
  const today = new Date();
  const isTicketDay = today.getDate() === 1 && today.getMonth() === 2;

  return (
    <div className={styles.maintenanceContainer}>
      <Helmet>
        <title>{festivalInformation.title}</title>
      </Helmet>
      <img
        src={festivalInformation.logo}
        alt="Festival Logo"
        className={styles.maintenanceLogo}
      />
      <h1 className={styles.maintenanceDate}>{festivalInformation.date}</h1>
      {isTicketDay && (
        <a
          href="https://shop.stamhoofd.be/scholfestival-2025/"
          className={styles.ticketLink}
        >
          Bestel Turbo Early Birds tickets
        </a>
      )}
    </div>
  );
};

export default Maintenance;
