import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import PublicLayout from "./layouts/PublicLayout";
import HomePage from "./pages/Home";
import LineUpPage from "./pages/LineUp";
import BandPage from "./pages/Band";
import MaintenancePage from "./pages/Maintenance";
import NotFoundPage from "./pages/NotFound";

function App() {
  const inMaintenance = true;

  if (inMaintenance) {
    // Toon alleen de 'witte pagina' met logo en datum
    return (
      <Routes>
        <Route path="*" element={<MaintenancePage />} />
      </Routes>
    );
  }

  // “Normale” website als we niet in onderhoud zijn
  return (
    <Routes>
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<HomePage />} />
        <Route path="/line-up" element={<LineUpPage />} />
        <Route path="/band/:bandId" element={<BandPage />} />
        <Route path="/notfound" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/notfound" replace />} />
      </Route>
    </Routes>
  );
}

export default App;
