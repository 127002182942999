import React from "react";

import Maintenance from "../components/Maintenance";

const MaintenancePage = () => {

  return (
    <Maintenance />
  );
};

export default MaintenancePage;